<template>
  <v-row class="mx-0">
    <v-col :md="vShowCalendar ? 4 : 12" class="py-0">
      <v-row>
        <v-col :md="vShowCalendar ? 12 : 8">
          <v-row class="custom-grey-border">
            <v-col
              md="12"
              class="light-gray-background custom-border-bottom d-flex justify-space-between"
            >
              <div
                style="font-size: 19px"
                class="text-uppercase font-weight-700 color-custom-blue"
              >
                Recurring Schedule
              </div>
              <div>
                <v-btn
                  :disabled="jobLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  right
                  small
                  depressed
                  v-on:click="updateCalendar()"
                >
                  <v-icon small class="mr-2">mdi-calendar-blank</v-icon>
                  <span v-if="vShowCalendar">Hide Calendar</span>
                  <span v-else>Show Calendar</span>
                </v-btn>
              </div>
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 12">
              <v-select
                dense
                filled
                class="schedule-type-list"
                :disabled="jobLoading"
                item-color="cyan"
                :items="recurringScheduleTypeList"
                placeholder="Schedule Type"
                solo
                flat
                :value="recurringSchedule.type"
                return-object
                v-on:change="updateWeekDays($event)"
                hide-details
                color="cyan"
              />

              <div
                class="mt-2"
                v-if="recurringSchedule?.type?.group == 'monthly'"
              >
                <v-radio-group
                  :disabled="jobLoading"
                  class="mt-0"
                  hide-details
                  mandatory
                  :value="recurringSchedule.recurring_pattern"
                  v-on:change="
                    updateScheduleState(
                      'dbRecurringSchedule.recurring_pattern',
                      $event
                    )
                  "
                >
                  <v-radio
                    :disabled="jobLoading"
                    :value="1"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'disabled-rw pointer-events-none':
                            recurringSchedule.recurring_pattern != 1,
                        }"
                        class="d-flex align-center font-weight-500"
                      >
                        <label class="text-center my-0 ml-2"
                          >Selection by Date</label
                        >
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    :disabled="jobLoading"
                    :value="2"
                    hide-details
                    color="cyan"
                  >
                    <template v-slot:label>
                      <div
                        :class="{
                          'disabled-rw pointer-events-none':
                            recurringSchedule.recurring_pattern != 2,
                        }"
                        class="d-flex align-center font-weight-500"
                      >
                        <div style="width: 150px">
                          <v-select
                            dense
                            filled
                            :disabled="jobLoading"
                            item-color="cyan"
                            :items="durationList"
                            item-text="long_name"
                            item-value="id"
                            placeholder="Select Day"
                            v-model="recurringSchedule.week_day"
                            v-on:change="updateSchedule()"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          />
                        </div>
                        <div style="width: 150px">
                          <v-select
                            dense
                            filled
                            :disabled="jobLoading"
                            item-color="cyan"
                            item-text="long_name"
                            item-value="id"
                            :items="weekDays"
                            placeholder="Select Week Day"
                            v-model="recurringSchedule.week_count"
                            v-on:change="updateSchedule()"
                            solo
                            flat
                            hide-details
                            color="cyan"
                          />
                        </div>
                        <template
                          v-if="
                            recurringSchedule?.type?.value == 'twice_a_month'
                          "
                        >
                          <div class="ml-10" style="width: 150px">
                            <v-select
                              dense
                              filled
                              :disabled="jobLoading"
                              item-color="cyan"
                              :items="durationList"
                              item-text="long_name"
                              item-value="id"
                              placeholder="Select Day"
                              v-model="recurringSchedule.second_week_day"
                              v-on:change="updateSchedule()"
                              solo
                              flat
                              hide-details
                              color="cyan"
                            />
                          </div>
                          <div style="width: 150px">
                            <v-select
                              dense
                              filled
                              :disabled="jobLoading"
                              item-color="cyan"
                              item-text="long_name"
                              item-value="id"
                              :items="weekDays"
                              placeholder="Select Week Day"
                              v-model="recurringSchedule.second_week_count"
                              v-on:change="updateSchedule()"
                              solo
                              flat
                              hide-details
                              color="cyan"
                            />
                          </div>
                        </template>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>

              <v-row v-if="recurringSchedule?.type?.group == 'weekly'">
                <v-col
                  class="py-0 my-0"
                  md="2"
                  v-for="(weekday, index) in weekDays"
                  :key="`job-weekday-${index}`"
                >
                  <v-checkbox
                    color="cyan"
                    :disabled="jobLoading || isDisabled(weekday.id)"
                    :label="
                      vShowCalendar ? weekday.short_name : weekday.long_name
                    "
                    :input-value="+recurringSchedule.weeks.includes(weekday.id)"
                    :true-value="1"
                    :false-value="0"
                    v-on:change="updateSelection(weekday.id)"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 4">
              <label class="ml-2" for="visit-one-off-start-date"
                >Start Date</label
              >
              <DatePicker
                :disabled="jobLoading"
                v-on:change="
                  updateScheduleState('dbRecurringSchedule.start_date', $event);
                  updateEndDate(true);
                "
                :value="recurringSchedule.start_date"
                key="visit-one-off-start-date"
                id="visit-one-off-start-date"
                placeholder="Start Date"
              />
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 4">
              <label class="ml-2" for="visit-one-off-start-time"
                >Start Time</label
              >
              <TimePicker
                :disabled="jobLoading"
                v-on:change="
                  updateScheduleState('dbRecurringSchedule.start_time', $event)
                "
                :value="recurringSchedule.start_time"
                key="visit-one-off-start-time"
                id="visit-one-off-start-time"
                placeholder="Start Time"
                type="start-time"
              />
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 4">
              <label class="ml-2" for="visit-one-off-end-time">End Time</label>
              <TimePicker
                :disabled="jobLoading"
                v-on:change="
                  updateScheduleState('dbRecurringSchedule.end_time', $event)
                "
                :value="recurringSchedule.end_time"
                key="visit-one-off-end-time"
                id="visit-one-off-end-time"
                placeholder="End Time"
                type="end-time"
              />
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 5">
              <v-radio-group
                :disabled="jobLoading"
                class="mt-0"
                hide-details
                mandatory
                :value="recurringSchedule.end_mode"
                v-on:change="
                  updateScheduleState('dbRecurringSchedule.end_mode', $event)
                "
              >
                <v-radio
                  :disabled="jobLoading"
                  :value="2"
                  hide-details
                  color="cyan"
                >
                  <template v-slot:label>
                    <div
                      class="d-flex align-center"
                      :class="{
                        'disabled-rw pointer-events-none':
                          recurringSchedule.end_mode != 2,
                      }"
                    >
                      <div>End after</div>
                      <div class="mx-2">
                        <v-text-field
                          dense
                          :disabled="
                            jobLoading || recurringSchedule.end_mode != 2
                          "
                          filled
                          :value="recurringSchedule.occurrence"
                          type="number"
                          placeholder="occurrences"
                          v-on:change="
                            updateScheduleState(
                              'dbRecurringSchedule.occurrence',
                              $event
                            )
                          "
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div>occurrences</div>
                    </div>
                  </template>
                </v-radio>
                <v-radio
                  :disabled="jobLoading"
                  :value="3"
                  hide-details
                  color="cyan"
                  v-on:click="updateEndDate(true)"
                >
                  <template v-slot:label>
                    <div
                      class="d-flex align-center"
                      :class="{
                        'disabled-rw pointer-events-none':
                          recurringSchedule.end_mode != 3,
                      }"
                    >
                      <div class="mr-2">End after</div>
                      <DatePicker
                        :disabled="
                          jobLoading || recurringSchedule.end_mode != 3
                        "
                        :value="
                          recurringSchedule.end_mode == 3
                            ? recurringSchedule.end_date
                            : ''
                        "
                        v-on:change="
                          updateScheduleState(
                            'dbRecurringSchedule.end_date',
                            $event
                          )
                        "
                        :min-date="recurringSchedule.start_date"
                        key="visit-recurring-end-date"
                        placeholder="Select End Date"
                      />
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col md="12">
              <table width="100%">
                <tr>
                  <td
                    class="m-0 px-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                  >
                    {{ recurringSchedule.message }}
                  </td>
                  <td width="150px" class="px-0 py-0">
                    <v-btn
                      v-if="scheduleOutput.length"
                      :disabled="jobLoading || loadingDialog"
                      :loading="loadingDialog"
                      v-on:click="openDialog()"
                      :style="`min-height: ${
                        vShowCalendar ? '56px' : '36.5px'
                      }; width: 100%;`"
                      color="cyan white--text"
                      depressed
                      class="custom-bold-button"
                    >
                      Show Schedule
                    </v-btn>
                    <v-btn
                      v-else
                      :disabled="jobLoading || loadingDialog"
                      :loading="loadingDialog"
                      v-on:click="showSchedule()"
                      :style="`min-height: ${
                        vShowCalendar ? '56px' : '36.5px'
                      }; width: 100%;`"
                      color="cyan white--text"
                      depressed
                      class="custom-bold-button"
                    >
                      Get Schedule
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="vShowCalendar ? 12 : 4">
          <ScheduleTeam :key="`job-recurring-schedule-team-${scheduleKey}`" />
        </v-col>
      </v-row>
      <ShowSchedule
        :dialog="scheduleDialog"
        :key="`job-show-schedule-${uniqueId}`"
        v-on:close="scheduleDialog = false"
        v-on:next="nextStepSchedule()"
      />
      <ConfirmSchedule
        :dialog="scheduleConfirmDialog"
        :key="`job-confirm-schedule-${uniqueCId}`"
        v-on:close="scheduleConfirmDialog = false"
      />
    </v-col>
    <v-col v-if="vShowCalendar" md="8">
      <Calendar
        :key="`job-recurring-calendar-${scheduleKey}`"
        :show-calendar="vShowCalendar"
      ></Calendar>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ScheduleTeam from "@/view/components/ScheduleTeam.vue";
import ShowSchedule from "@/view/components/ShowSchedule.vue";
import ConfirmSchedule from "@/view/components/ConfirmSchedule.vue";
import DatePicker from "@/view/components/QDatePicker.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import Calendar from "@/view/pages/partials/Calendar.vue";
import {
  UPDATE_DEFAULT_DATE,
  GET_RECURRING_DATE,
  UPDATE_CALENDAR_ACTION,
  UPDATE_SCHEDULE_STATE,
  RESET_ENGINEER,
} from "@/core/services/store/job.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-recurring-schedule",
  data() {
    return {
      scheduleConfirmDialog: false,
      scheduleDialog: false,
      loadingDialog: false,
      uniqueId: Number(new Date()),
      uniqueCId: Number(new Date()),
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      scheduleKey: Number(new Date()),
      timeOut: null,
      dialogTimeOut: null,
    };
  },
  methods: {
    updateSelection(dayId) {
      const isSelected = this.recurringSchedule.weeks.includes(dayId);
      if (isSelected) {
        this.recurringSchedule.weeks = this.recurringSchedule.weeks.filter(
          (selectedDay) => selectedDay !== dayId
        );
      } else {
        if (this.recurringSchedule.weeks.length < this.allowedSelections()) {
          this.recurringSchedule.weeks.push(dayId);
        }
      }

      this.$nextTick(() => {
        this.updateSchedule();
      });
    },
    allowedSelections() {
      const selectedFrequencyData = this.recurringScheduleTypeList.find(
        (item) => item.value === this.recurringSchedule?.type?.value
      );
      return selectedFrequencyData ? selectedFrequencyData?.max_allowed : 0;
    },
    isDisabled(dayId) {
      return (
        this.allowedSelections() === this.recurringSchedule.weeks.length &&
        !this.recurringSchedule.weeks.includes(dayId)
      );
    },
    nextStepSchedule() {
      this.scheduleDialog = false;
      this.$nextTick(() => {
        this.uniqueCId = Number(new Date());
        this.scheduleConfirmDialog = true;
      });
    },
    showSchedule() {
      this.$store.dispatch(GET_RECURRING_DATE);
      this.uniqueId = Number(new Date());
    },
    openDialog() {
      if (this.scheduleOutput.length) {
        this.loadingDialog = true;
        this.$store.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbScheduleOutput",
          value: this.rawScheduleOutput,
        });
        clearTimeout(this.dialogTimeOut);
        this.dialogTimeOut = setTimeout(() => {
          this.scheduleDialog = true;
          this.loadingDialog = false;
          this.scheduleConfirmDialog = false;
        }, 200);
      }
    },
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    updateWeekDays(param) {
      this.updateScheduleState("dbRecurringSchedule.occurrence", 1);
      this.updateScheduleState("dbRecurringSchedule.weeks", []);
      this.updateScheduleState("dbRecurringSchedule.type", param);
      this.updateEndDate(true);
    },
    updateEndDate(force) {
      let startDateTime = moment(
        `${this.recurringSchedule.start_date} ${this.recurringSchedule.start_time}`
      );
      let endDateTime = moment(
        `${this.recurringSchedule.end_date} ${this.recurringSchedule.end_time}`
      );

      if (force === true || endDateTime.isSameOrBefore(startDateTime)) {
        let endDate = null;
        switch (this.recurringSchedule?.type?.value) {
          case "daily":
            endDate = startDateTime.add(1, "day").format("YYYY-MM-DD");
            break;
          case "weekly":
            endDate = startDateTime.add(7, "day").format("YYYY-MM-DD");
            break;
          case "twice_a_week":
            endDate = startDateTime.add(3, "day").format("YYYY-MM-DD");
            break;
          case "fort_nightly":
            endDate = startDateTime.add(14, "day").format("YYYY-MM-DD");
            break;
          case "thrice_a_week":
            endDate = startDateTime.add(2, "day").format("YYYY-MM-DD");
            break;
          case "monthly":
            endDate = startDateTime.add(1, "month").format("YYYY-MM-DD");
            break;
          case "twice_a_month":
            endDate = startDateTime.add(1, "month").format("YYYY-MM-DD");
            break;
          case "bi_monthly":
            endDate = startDateTime.add(2, "month").format("YYYY-MM-DD");
            break;
          case "quarterly":
            endDate = startDateTime.add(3, "month").format("YYYY-MM-DD");
            break;
          case "bi_annually":
            endDate = startDateTime.add(6, "month").format("YYYY-MM-DD");
            break;
          case "annually":
            endDate = startDateTime.add(1, "year").format("YYYY-MM-DD");
            break;
        }
        this.updateScheduleState("dbRecurringSchedule.end_date", endDate);
      }
    },
    updateSchedule() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.showSchedule();
      }, 200);
    },
    updateDefaultDate() {
      this.$nextTick(() => {
        this.$store.dispatch(UPDATE_DEFAULT_DATE, "recurring");
      });
    },
    updateScheduleState(key, value) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
      this.$store.commit(RESET_ENGINEER);
      this.updateDefaultDate();
      this.updateSchedule();
    },
    updateCalendar() {
      this.$store.commit(UPDATE_CALENDAR_ACTION);
    },
  },
  components: {
    ScheduleTeam,
    ShowSchedule,
    ConfirmSchedule,
    Calendar,
    TimePicker,
    DatePicker,
  },
  mounted() {
    this.updateSchedule();
    this.updateDefaultDate();
  },
  computed: {
    ...mapGetters([
      "defaultStartedAt",
      "defaultFinishedAt",
      "durationList",
      "vShowCalendar",
      "recurringScheduleTypeList",
      "weekDays",
      "recurringSchedule",
      "jobLoading",
      "scheduleOutput",
      "rawScheduleOutput",
    ]),
  },
};
</script>
